<template>
  <div class="violations__save-button">
    <r-button
      type="success"
      :disabled="disabled"
      @click="$emit('save')">
      {{ 'Сохранить' }}
    </r-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.violations__save-button {
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: stretch;
  text-align: center;
}
</style>
